.grigora-action-btn {
  color: white;
  border-radius: 10px;
  padding: 10px 18px;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  font-weight: 500;

  @apply bg-primary;

  &.small {
    border-radius: 7px;
    font-size: 12px;
    font-weight: 600;
    padding: 7px 10px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.inactive {
    cursor: default;
    @apply bg-opacity-50;
  }

  &:not(.inactive):hover {
    @apply bg-primaryDark;
  }

  &.white {
    color: unset;
    @apply bg-ui-white;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-ui-whiteDark;
    }
  }

  &.red {
    @apply bg-ui-red;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-ui-redDark;
    }
  }

  &.blue {
    @apply bg-ui-og-blue;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-ui-og-blue-dark;
    }
  }

  &.transparent {
    color: black;
    @apply bg-transparent;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-gray-100;
    }
  }

  &.light {

    @apply bg-white text-primary border border-primary;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-gray-100;
    }


  }

}